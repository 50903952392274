$spinRadius: 20px;

zoined-spinner, .zoined-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;

  .spin-area {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: cog-rotate 4s linear infinite;

    .cog {
      position: absolute;

      top: -18px;
      left: -17px;
      text-align: center;
      color: #999;
      opacity: 0;

      &.cog-1 {
        transform: translate(-$spinRadius*0.866, -$spinRadius*0.5);
        animation: cog-appear 0.2s ease-out forwards;
      }
      &.cog-2 {
        transform: translate($spinRadius*0.866, -$spinRadius*0.5);
        animation: cog-appear 0.2s 0.1s ease-out forwards;
      }
      &.cog-3 {
        transform: translate(0px, $spinRadius);
        animation: cog-appear 0.2s 0.2s ease-out forwards;
      }
      .wheel {
        animation: cog-rotate 2s linear infinite;
      }

      .fa {
        font-size: 40px;
      }
    }
  }
}

zoined-spinner.ng-hide {
  animation: cog-disappear 0.2s ease-out forwards;
}

span.inline-spinner {
  margin-left: 5px;
  i {
    animation: cog-rotate 1.5s linear infinite;
  }
}

@keyframes cog-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes cog-appear {
  from {
    transform: scale(5, 5);
  }
  to {
    opacity: 1;
  }
}

@keyframes cog-disappear {
  from {
  }
  to {
    opacity: 0;
    transform: scale(0.2, 0.2);
  }
}
