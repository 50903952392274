/////////////////////////////
//// DASHBOARD //////////////
/////////////////////////////

// DASHBOARD TIME-DISPLAY LEGEND

.dashboard-legend {
  text-align: center;
  .legend {
    display: inline-block;
    text-align: left;
    color: $dashboard-legend;
    font-weight: 300;
    //@include gradient-vertical(lighten(#4d4d4d,25%), lighten(#4d4d4d,30%), 0%, 20%);
    border-radius: 5px;
    max-width: 100%;
    > div {
      @include text-overflow();
      > span {
        vertical-align: middle;
      }
    }
    i {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      cursor: auto;
    }
    .description {
      font-size: 100%;
      //float: right;
    }
    .daterange {
      padding-right: 10px;
    }
  }
}

//////////////////////////////
// SNIPPETS //////////////////
//////////////////////////////

.panel-snippet {
  background-color: $panel-snippet-bg;
  border-radius: $panel-snippet-border-radius;
  box-shadow: none;
  @include panel-variant($panel-snippet-border, $panel-snippet-text, $panel-snippet-heading-bg, $panel-snippet-border);
  border: 0px solid transparent;
  margin: 0 0 30px 0;
  padding: 0 30px;
  position: relative;
  .show-more {
    text-align: center;
    display: block;
    border-bottom: 1px solid $panel-snippet-inner-border;
    cursor: pointer;
    @include user-select(none);
    @include gradient-radial(rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0));
    &:hover {
      @include gradient-radial(rgba(75, 205, 240, 0.06), rgba(0, 0, 0, 0));
    }
    i.fa:before {
      content: "\f077";
    }
  }
  .show-more.collapsed {
    i.fa:before {
      content: "\f078";
    }
    border-bottom: none;
  }
  .panel-title {
    font-family: $copy-font;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    line-height: 21px;
    vertical-align: middle;

    i.fa.fa-lg {
      //float: right;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 15px;
      &:hover {
        color: $zoined-blue;
      }
      line-height: 21px;
      @media print {
        display: none;
      }
    }
    .tooltip {
      font-weight: 300;
      font-family: $copy-font;
      font-size: $font-size-small;
    }
  }
  .panel-heading,
  .panel-footer {
  }
  .panel-footer {
    background-color: $panel-snippet-footer-bg;
    border-top: 1px solid $panel-snippet-inner-border;
    //@include border-bottom-radius(($panel-snippet-border-radius - 1));
  }
  .panel-heading {
    padding: $panel-snippet-heading-padding;
  }
  .panel-body {
    padding: $panel-snippet-body-padding;

    .snippet {
      max-width: 100%;
      overflow: hidden;

      // TABLES IN SNIPPETS, Zorbas_Table for example
      table {
        max-width: 99.5%;
        margin: 0 auto;
        margin-bottom: 10px;
        thead {
          th {
            font-weight: 400;
            font-family: $copy-font;
            font-size: $font-size-small;
            text-align: left;
            padding: 5px 10px;
          }
        }

        tbody {
          border: #cecece solid 1px;

          tr:nth-child(odd) {
            background-color: #f4f4f3;
          }

          td {
            border-right: #cecece solid 1px;

            padding: 5px 10px;
            text-align: center;
            &.left {
              text-align: left;
            }
            &.right {
              text-align: right;
            }
            &.emph {
              font-weight: bold;
            }

            a.image {
              img {
                max-width: 100px;

                // Override bootstrap max-width rule
                @media print {
                  max-width: 100px !important;
                }
              }
            }
          }
        }
        tfoot {
          border: #cecece solid 1px;
          td {
            font-weight: bold;
            border-right: #cecece solid 1px;
            padding: 5px 10px;
            text-align: center;
            &.left {
              text-align: left;
            }
            &.right {
              text-align: right;
            }
            &.emph {
              font-weight: bold;
            }
          }
        }
        &.sok_provision {
          // Set max width for store column
          td:nth-child(2) {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.basket-cross-sell {
          tr:first-child {
            td:first-child {
              // Allow basket cross sell table basket selection to wrap
              white-space: normal !important;
            }
          }
        }
      }
    }
  }

  .values {
    line-height: 1.1;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
  }
  .small-values {
    margin-top: 12px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: $screen-sm-min) {
      font-size: 16px;
    }
    @media (min-width: $screen-md-min) {
      font-size: 22px;
    }
    @media (min-width: $screen-lg-min) {
      font-size: 28px;
    }
  }
  .value {
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-size: 9vw;

    @media (min-width: $screen-sm-min) {
      font-size: 36px;
    }
    @media (min-width: $screen-md-min) {
      font-size: 52px;
    }
    @media (min-width: $screen-lg-min) {
      font-size: 66px;
    }
  }
  .small-value {
    font-size: 11px;
    line-height: 24px;
    color: #26282a;
    // position: absolute;
    // top: 15px;
    // @media (min-width: $screen-sm-min) {
    //   top: 6px;
    // }
    // @media (min-width: $screen-md-min) {
    //   top: 12px;
    // }
    // @media (min-width: $screen-lg-min) {
    //   top: 15px;
    // }
  }
  .notifications .value {
    font-weight: 300;
    line-height: 14px;
    .percentage {
      padding-right: 4px;
      line-height: 1;
      font-size: 0.8em; // to keep it relative to snippet value font size
    }
    .number {
      // the small number next to percentage
      color: #4d4d4d;
      font-weight: 400;
      line-height: 1;
      font-size: 4vw;

      @media (min-width: $screen-sm-min) {
        font-size: 12px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 18px;
      }
      @media (min-width: $screen-lg-min) {
        font-size: 21px;
      }
    }
  }
  .value,
  .abs,
  .rel {
    display: inline-block;
    font-family: $display-font;
  }
  .value-label {
    text-transform: uppercase;
    margin-bottom: 1em;
    letter-spacing: 0.1em;
  }
  // the 'bar chart' on the right side of the snippet
  .g {
    &.selection {
      padding-top: 0;
      @media (min-width: $screen-sm-min) {
        padding-top: 0;
      }
      @media (min-width: $screen-md-min) {
        padding-top: 4px;
      }
      @media (min-width: $screen-lg-min) {
        padding-top: 10px;
      }
    }
    &.comparison {
      padding-top: 15px;
      @media (min-width: $screen-sm-min) {
        padding-top: 6px;
      }
      @media (min-width: $screen-md-min) {
        padding-top: 12px;
      }
      @media (min-width: $screen-lg-min) {
        padding-top: 15px;
      }
    }
  }
  .b {
    height: 24px;
    line-height: 24px;
  }
  .dashbox-scale {
    font-size: 11px;
    font-weight: normal;
    line-height: 1;
    text-align: right;
    color: #606060;
    height: 4px;
    overflow: visible;
    .graph-min {
      float: left;
      margin-top: -17px;
    }
    .graph-max {
      float: right;
      margin-top: -17px;
    }
    .graph-frame {
      margin-top: 17px;
      height: 500px;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
    }
  }
}
.dashboard-body {
  .panel-body {
    zoined-snippet,
    .zoined-snippet {
      overflow: hidden;
      min-height: 70px;
    }
  }
}

.report-body {
  .panel-snippet {
    .panel-body {
      zoined-snippet {
        min-height: 70px;
        .snippet {
          overflow-x: auto;
        }
      }
      .chart {
        height: auto !important;
        @media (min-width: $screen-sm-min) {
          height: auto;
        }
      }
    }
  }
}

.tooltip-inner {
  padding: 5px;
  word-wrap: normal;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

// THE 'DETAIL' COMPONENT OF THE SNIPPETS

.snippet-detail {
  position: relative;

  .panel-title {
    margin-top: 4px;
    margin-right: 15px;
  }
}
.snippet-chart-wrapper {
  position: relative;
}

.bottom-shadow {
  height: 100%;
  position: absolute;
  top: 0;
}

.chart {
  overflow: hidden;
  width: 100%;
  clear: both;
}

.graph-pagination {
  text-align: center;
  position: relative;
  .pagination {
    margin: 0;
  }
  .pagination > li > a {
    cursor: pointer;
  }
  .pagination > .disabled > a {
    cursor: not-allowed;
  }
  .pagination > .disabled.active > a {
    cursor: default;
    &:hover,
    &:focus {
      cursor: default;
    }
  }
}

// MEDIA QUERIES CONTROLLING THE WIDTH OF THE 'DOUBLE' COLUMN GRAPH

@media (min-width: $screen-sm-min) {
  .snippet-chart-wrapper {
    width: ($container-sm - 30px);
    position: relative;
  }
  .chart {
    max-width: ($container-sm - 30px - 15px);
  }
  .bottom-shadow {
    width: floor(($container-sm - 30px)/2+15px);
    @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  }
  .dashboard-snippet:nth-child(odd) {
    .bottom-shadow {
      right: 0;
    }
  }
}
@media (min-width: $screen-md-min) {
  .snippet-chart-wrapper {
    width: ($container-md - 30px);
  }
  .chart {
    max-width: ($container-md - 30px - 15px);
  }
  .bottom-shadow {
    width: floor(($container-md - 30px)/2+15px);
  }
}
@media (min-width: $screen-lg-min) {
  .snippet-chart-wrapper {
    width: ($container-lg - 30px);
  }
  .chart {
    max-width: ($container-lg - 30px - 15px);
  }
  .bottom-shadow {
    width: floor(($container-lg - 30px)/2+15px);
  }
}

.nav > li#dashboards-dropdown.dropdown.open {
  position: static;
}
.nav > li#dashboards-dropdown.dropdown.open #dashboards-menu {
  width: 100%;
  text-align: left;
  left: 0;
  right: 0;
  padding: 0px 40px 30px 40px;
  max-height: 700px;
  ul.multi-column-dropdown,
  ul.multi-column-dropdown-header {
    padding: 0;
    list-style-type: none;
    li.dropdown-header {
      padding: 5px 15px 5px 20px;
    }
    li:not(.dropdown-header) {
      a {
        &:hover {
          color: $zoined-blue;
        }
      }
    }
    li.dashboard-link {
      a {
        padding-top: 0;
        padding-bottom: 0;
        white-space: pre-wrap;
      }
    }
  }
  ul.multi-column-dropdown {
    max-height: 560px;
    overflow: auto;
  }
  .create-new {
    padding-left: 20px;
    margin-top: 10px;
  }
}
