.highcharts-tooltip
{
  .basket_sales {
    font-weight: bold;
  }
  .date {
    font-size: 0.8em;
  }

  table.product-listing
  {
    margin-top: 10px;
    font-size: 0.8em;
    width: 100%;
    min-width: 200px;
    .name {
      overflow: hidden;
    }
    .pcs,
    .price {
      padding-left: 10px;
      text-align: right;
    }
  }
}
