.font-size-xs {
  font-size: 10px;
}
.font-size-sm {
  font-size: 12px;
}
.font-size-md {
  font-size: 13px;
}
.font-size-lg {
  font-size: 15px;
}
.font-size-xl {
  font-size: 18px;
}
.font-weight-md {
  font-weight: 400;
}
.font-weight-sb {
  font-weight: 600;
}
.letter-spacing-wide {
  letter-spacing: 0.1em;
}
