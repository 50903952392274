
.modal-header {
  background: $zoined-blue;
  color: white;
  padding: 10px 15px;

  h4.modal-title {
    font-size: 1.3em;
    display: inline;
  }
  .close {
    color: white;
    font-size: 2em;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

.modal-open {
  overflow: initial !important;
  padding-right: 0px !important;
}

.modal-backdrop {
  opacity: 0.5;
}

#edit-modal, #password-modal, #email-modal, #add-subscription-modal {
  margin-top: 45px;
  z-index: 2147483647;
}
