//////////////////////////////////
//// FORMS ///////////////////////
//////////////////////////////////

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  display: block;
  width: 100%;
  max-width: 300px;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
  @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  @include placeholder();

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content
  }

  // [converter] extracted textarea& to textarea.form-control
}

.selector .multiselect__input,
.selector .multiselect__single {
  font-size: 12px !important;
  border: none !important;
}

.selector .multiselect__option {
  padding: 6px 12px;
  min-height: 30px;
  line-height: 14px !important;
  font-size: 12px !important;
}

.selector .multiselect__option--highlight {
  background-color: #81e4ff !important;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: auto !important;
}

/* Simple Form */
.form-horizontal {
  .checkbox {
    padding-left: 20px;
  }
}

.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  .row {
    > div {
      padding-left: 0;
    }
  }
}

.validation-error {
  color: $brand-danger;
}
