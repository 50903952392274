zoined-chart-options {
  .orientation {
    float: left;
    
    a {
      display: block;
      float: left;
      padding: 15px 0px;
      font-size: 1.5em;
      line-height: 20px;
      color: $gray-light;
    }

    a.active {
      color: #4D4D4D;
    }
  }

  .data-labels {
    font-size: 12px;
    line-height: 20px;
    padding: 15px 15px 15px 5px;
    float: left;

    label {
      font-weight: normal;
    }
  }

  .delta-values {
    span.text {
      display: block;
      float: left;
      padding: 15px 2px;
      line-height: 20px;
      font-size: 12px;
    }

    a {
      display: block;
      float: left;
      padding: 15px 2px;
      line-height: 20px;
      font-weight: bold;
      color: $gray-light;
    }

    a.active {
      color: #4D4D4D;
    }
  }
}
