@media print {
  @page {
    margin: 1cm 1cm 4cm 1cm;
  }

  body {
    padding: 0px !important;
    width: 100% !important;
    // Print backgrounds.
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  header {
    background-color: #ffffff !important;
    border-bottom: 2px solid #3d3d3d !important;
    text-align: center;

    nav#print-nav {
      height: 60px;
      .logo {
        position: absolute;
        padding: 0;
        top: 10px;
        left: 10px;
        width: 152px;
        height: 40px;
        img {
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
      .text {
        padding-top: 20px;
        font-size: 16pt;
      }
    }
  }

  .container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  a[href]:after {
    content: "" !important;
  }
  .navigation,
  .personal,
  .show-more {
    display: none !important;
  }

  .fo-handle {
    display: none !important;
  }
  .export-link {
    display: none !important;
  }
  #selector_snippets {
    margin-right: 0 !important;
  }
  .dd .selected li,
  .selectors ul li {
    margin-right: 0 !important;
  }

  .brand a {
    background: url("~@assets/images/logo.png") no-repeat scroll left top transparent !important;
  }
  .dashboard-metric h3 {
    border-bottom: 1px solid #cccccc !important;
    font-size: 1em !important;
  }

  .panel {
    page-break-inside: avoid;
    overflow-x: hidden !important;

    .panel-heading {
      padding-top: 0;
    }

    table {
      page-break-after: always;

      thead {
        page-break-inside: avoid;
      }
      tr {
        page-break-inside: avoid;
      }

      &.crosstab {
        width: 100%;
        th {
          white-space: normal !important;
        }
        th,
        td {
          font-size: 8px !important;
          min-width: 0 !important;
          padding: 2px !important;
          &.column {
            max-width: 50px !important;
          }
        }
      }
    }
  }
  .analytics .panel.panel-snippet {
    page-break-inside: auto; /* Leaving page-break-avoid will cause strange duplicated overlapping thead+tfoot with some table lengths */
  }

  .panel-snippet {
    padding: 0px !important;
    margin: 0px !important;
    .panel-body {
      padding: 0px;
    }
    border-radius: 0;
  }
  .dashboard-metric .value {
    font-size: 3.3em !important;
  }
  .dashboard-metric h3 {
    border-bottom: 1px solid #cccccc !important;
    position: relative !important;
  }
  .btn-inverse {
    background: #393939 !important;
    color: #fff !important;
  }
  .dashboard-metric .value-change p {
    font-size: 1.3em !important;
  }
  .pos,
  .pos .percentage {
    color: $good-change !important;
  }
  .dashboard-metric.notifications .value {
    font-size: 3em !important;
  }
  .neg,
  .neg .percentage {
    color: $bad-change !important;
  }

  .dashboard-metric .left-side {
    float: left !important;
    width: 42% !important;
  }
  .breadc li a,
  .breadc li span,
  .breadc li p {
    color: #bababa;
    font-size: 1em !important;
  }
  .graph-pagination {
    display: none !important;
  }
  #snippets-container p {
    margin: 0 !important;
    padding: 0 0 10px 0 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: left;
  }
  .row {
    /*height: auto !important;*/
  }
  .snippet {
    min-height: 50px !important;
    overflow: hidden !important;
  }
  .btn {
    display: none;
  }
  #filters {
    display: none !important;
  }
  // Hide tours.
  .popover {
    display: none !important;
  }

  zoined-chart-options {
    display: none !important;
  }

  .analytics zoined-block .paginated-block:last-child {
    padding-bottom: 0px;
  }

  // Hide drilldown
  // Hide survey monkey surveys
  .drilldown-wrapper,
  .smcx-modal {
    display: none !important;
  }

  .pagination-control {
    display: none !important;
  }
}
