.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.dropdown-menu li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  // color: #333333;
  white-space: nowrap;
}

.dropdown-menu {
  // Show over modals
  z-index: var(--z-index-dropdown) !important;
}
