/////////////////////////
//// ANALYTICS LANDING //
/////////////////////////

.analytics-categories {
  .dropdown-menu {
    //z-index: 1000;
    right: 0;
    left: auto;
    width: 230px;
    & > li > a {
      white-space: normal;
    }
  }

  @media (max-device-width: 320px) and (orientation: portrait) {
    .dropdown-menu {
      right: -45px;
    }
  }

  .col-md-3.category {
    text-align: center;

    .large-icon {
      width: 100%;
      background-size: contain;
      background-position: 50% 50%;
    }

    .graphic-col,
    .large-icon {
      height: 100%;
      min-height: 160px;
    }

    @media (min-width: $screen-md-min) {
      padding-left: 7px;
      padding-right: 7px;

      p.description {
        min-height: 10em;
      }

      h3.category-title {
        border-top: 1px solid #cccccc;
        margin: 0.8em 0 0.5em 0;
        padding-top: 0.7em;
      }

      .graphic-col,
      .large-icon {
        height: auto;
      }
    }

    @media (max-device-width: 320px) and (orientation: portrait) {
      /* Styles */

      .graphic-col,
      .other-col {
        display: block;
        width: 100%;
      }
      .graphic-col,
      .large-icon {
        height: auto;
        display: block;
      }
    }

    h3.category-title {
      font-size: 2em;
    }
  }
}

#main.analytics,
#main.reports {
  // Adapted from Johnny's design proposal, Feb 2016.
  .index2 {
    margin-top: 15px;

    .panel {
      border-bottom-color: $underline-accent;
      border-bottom-width: 3px;

      .panel-heading {
        padding-top: 30px;
        padding-bottom: 5px;
        border-bottom: none;

        .large-icon {
          width: 82px;
          height: 82px;
          background-size: 90px;
          background-color: #ffffff;
          background-position: center;
          border-radius: 0;
          margin: 0;
        }

        .heading-texts {
          @media (min-width: $screen-sm-min) {
            margin-left: 110px;
          }
          // Johnny: title 32px uppercase, font Oswald Regular
          h2 {
            text-transform: uppercase;
            font-size: 32px;
            display: inline-block;
          }
          // Johnny: description 14px (open sans)
          .description {
            font-size: 14px;
          }
        }
      }

      .panel-body {
        .row.report-link {
          // The row is too wide for a separator border.. This is on a col-12.
          hr {
            margin: 0;
          }

          // Johnny: 12 px open sans uppercase
          h3 {
            font-family: $copy-font;
            font-weight: bold;
            font-size: 12px;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
            padding: 15px 0;
            &.i_fa {
              font-size: 2rem;
              padding: 1rem 0 0 0;
              .fa-dashboard {
                color: rgb(75, 205, 240);
              }
            }
          }

          // info text  12px open sans
          .description {
            font-size: 12px;
            line-height: normal;
            color: #84827d;
            padding: 15px 0;
          }

          &:hover {
            background-color: #f4f4f3;
          }
        }

        &.saved-reports {
          .row {
            .delete-button {
              padding-top: 6px;
              display: none;
            }
            &:hover {
              .delete-button {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .large-icon {
    display: block;
    // Center the icon in the containing div.
    margin: 0 auto;
  }

  // Old style menus on report pages.
  ul.dropdown-menu.reports {
    h3 {
      color: #4bcdf0;
      font-size: 100%;
      text-transform: uppercase;
      margin: 4px 0 4px 0;
      line-height: 1.2;
    }
    p {
      color: #000;
    }
  }

  .panel {
    .panel-heading {
      h2 {
        margin-top: 0;
      }
      p.description {
        margin: 0;
      }
    }
  }

  .help-block {
    margin: 0 3em 30px 3em;
    i {
      float: left;
      margin-left: -2em;
    }
  }
}

//////////////////////////
//// ANALYTICS: REPORTS //
//////////////////////////

ul.breadc {
  margin: 0;
  padding: 0;
  display: flex;

  @media (max-width: $grid-float-breakpoint) {
    .form-group {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  > li {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: block;
    }
  }

  > li.active {
    display: block;
  }

  > li.divider > .navbar-text {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  > li:first-child > a,
  > li:first-child > .navbar-text {
    padding-left: 0;
  }

  > li > a,
  > li > .btn-group > a,
  > li > .navbar-text,
  > li > p {
    font-family: $display-font;
    font-size: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
    margin: 0;
    display: block;
    position: relative;
    color: #bababa;
  }
  > li.active > a,
  > li.active > .navbar-text,
  > li.active > .btn-group > a {
    color: #4d4d4d;
  }
  .dropdown-menu li > a {
    white-space: normal;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    // From bootstrap:  Dropdowns get custom display when collapsed
    // Cancel that
    .open .dropdown-menu {
      & > li > a {
        white-space: normal;
      }
    }
  }
}

$crosstabFontsize: 60%;
$padding: 5px 5px;

.report-component.crosstab {
  .zoined-snippet {
    min-height: 0;
    .pagination-control {
      display: none;
    }
    .content {
      div {
        overflow-x: hidden !important;
        box-sizing: border-box;
        table {
          width: 100%;
          left: 0;
          right: 0;
          thead {
            th {
              font-size: $crosstabFontsize;
              width: 5% !important;
              max-width: 5% !important;
              padding: $padding;
              text-align: right;
            }
            th:first-child {
              width: 15% !important;
              max-width: 15% !important;
              text-align: left;
            }
          }
          tbody {
            font-size: $crosstabFontsize;
            white-space: nowrap;
            td {
              padding: $padding;
            }
          }
          tfoot {
            font-size: $crosstabFontsize;
            white-space: nowrap;
            td {
              padding: $padding;
            }
          }
        }
      }
    }
  }
}

#add-component-modal .drilldown-wrapper,
.custom-dashboard-editor .drilldown-wrapper,
.custom-report-editor .drilldown-wrapper {
  display: none;
}

@page {
  size: A4 portait;
  margin: 0;
}
