.modal.user-invite {
  .modal-body {
    position: relative;
    .form-horizontal {
      transition: opacity 0.2s;

      &.disabled {
        opacity: 0.5;
      }
    }

    zoined-spinner {
      pointer-events: auto;
      opacity: 1;
    }

    .form-group {
      .filter-buttons {
        padding-top: 2px;
      }
      .selectors.pill-select {
        padding-top: 1px;
      }
      .help-block {
        font-size: smaller;
        margin-bottom: 0;
        margin-top: -2px;
        i.fa {
          padding-right: 4px;
        }
      }
    }
  }
}

.modal.user-dialog {
  .modal-body {
    .row + .row {
      margin-top: 15px;
      &:before {
        display: block;
        margin-left: 15px;
        margin-right: 15px;
        border-top: 1px solid #ddd;
      }
      h4 {
        margin-top: 20px;
      }
    }
    .help-block {
      font-size: smaller;
      margin-bottom: 0;
      margin-top: -2px;
      i.fa {
        padding-right: 4px;
      }
    }
  }
  label {
    font-weight: normal;
  }
}
