// Our variables
$btn-on-surface-bg: #f4f4f5;
$btn-on-surface-hover-bg: #ddd;
$black-95: #1a1a1a;
$black-90: #2e2e2f;
$black-80: #3d3d3d;
$black-60: #474747;

$zoined-red: #ef6345;
$background-color: #f8f8f8;

// Override bootstrap variables
$border-radius-small: 5px;
$border-radius-base: 10px;
$border-radius-large: 20px;

$btn-border-radius-small: $border-radius-large;
$btn-border-radius-base: $border-radius-large;
$btn-border-radius-large: $border-radius-large;

$input-border-radius: $border-radius-small;

$panel-snippet-border-radius: $border-radius-base;
$panel-border-radius: $border-radius-base;
$panel-body-padding: 20px;

$btn-default-border: white;

$pagination-bg: var(--color-interactive-bg-emphasis-1);
$pagination-border: $pagination-bg;
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border: $pagination-bg;
$pagination-hover-bg: var(--color-interactive-bg-emphasis-2);
$pagination-hover-border: $pagination-hover-bg;
$pagination-color: var(--color-text-variant);
$pagination-hover-color: var(--color-text-primary-inverse);

$dropdown-bg: var(--color-surface-neutral-1);
$dropdown-border: var(--color-surface-neutral-1);
$dropdown-link-color: var(--color-text-primary);

$grid-float-breakpoint: 992px;
