.panel-snippet .panel-body .snippet table.crosstab {
  thead {
    tr {
      th {
        border: 1px solid #cecece;
      }
    }
  }
  tbody {
    tr {
      background-color: white;
      &.crosstab-total {
        font-weight: bold;
        &.crosstab-level-0 {
          border-bottom: 1px solid #aaa;
          td {
            background-color: #ccc;
            border-right: 1px solid #aaa;
          }
        }
        &.crosstab-level-1 {
          border-bottom: 1px solid gray;
          td {
            border-right: 1px solid #bbb;
            background-color: #ddd;
          }
        }
        &.crosstab-level-2 {
          td {
            border-right: 1px solid #ccc;
            background-color: #eeeeee;
          }
        }
        &.crosstab-level-3 {
          td.value {
            background-color: #fefefe;
          }
        }
      }
      td {
        border-bottom: 1px solid #cecece;

        &.crosstab-row-total {
          font-weight: bold;
        }
      }
    }
  }
  th {
    text-align: center;
    min-width: 100px;
    max-width: 100px;

    &.crosstab-grouping-header {
      min-width: initial;
      max-width: initial;
    }
  }
  td {
    text-align: right;
    white-space: nowrap;

    min-width: 100px;
    max-width: 100px;
    overflow: hidden;

    &.crosstab-row-grouping {
      text-align: left;
      min-width: initial;
      max-width: initial;
    }
  }
}
