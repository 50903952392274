:root {
  --color-gray-950: #111111;
  --color-gray-850: #262626;
  --color-gray-750: #3b3b3b;
  --color-gray-650: #525252;
  --color-gray-550: #6a6a6a;
  --color-gray-450: #848484;
  --color-gray-350: #9e9e9e;
  --color-gray-250: #b9b9b9;
  --color-gray-150: #d4d4d4;
  --color-gray-050: #f1f1f1;
  --color-gray-025: #f8f8f8;

  --color-white: #ffffff;

  --color-cyan-900: #001f2a;
  --color-cyan-800: #053543;
  --color-cyan-700: #064d61;
  --color-cyan-600: #016781;
  --color-cyan-500: #0c81a1;
  --color-cyan-400: #359cbb;
  --color-cyan-300: #53b8d5;
  --color-cyan-200: #6ed4ef;
  --color-cyan-100: #b4ebfa;
  --color-cyan-050: #d6f6ff;
  --color-cyan-025: #ebfbff;
  --color-cyan-sat50: #7cb1c0;
  --color-cyan-op07: #84cbdf;
  --color-cyan-sat50-op07: #a1c6d0;

  --color-zoined-yellow-800: #432c00;
  --color-zoined-yellow-700: #604101;
  --color-zoined-yellow-600: #7f5703;
  --color-zoined-yellow-500: #9e6d05;
  --color-zoined-yellow-400: #bf8609;
  --color-zoined-yellow-300: #e29e0f;
  --color-zoined-yellow-250: #f4ab0c;
  --color-zoined-yellow-200: #f7bd5d;
  --color-zoined-yellow-150: #face8b;
  --color-zoined-yellow-050: #feefd9;
  --color-zoined-yellow-025: #fef7ec;

  --color-green-900: #092100;
  --color-green-800: #153801;
  --color-green-700: #215101;
  --color-green-600: #2e6c03;
  --color-green-500: #3b8703;
  --color-green-400: #4ea70e;
  --color-green-300: #7abc5d;
  --color-green-200: #a5d393;
  --color-green-100: #d1eac7;
  --color-green-050: #e8f4e3;
  --color-green-025: #f4faf2;
  --color-green-sat50: #547d36;
  --color-green-op07: #81bf54;
  --color-green-sat50-op07: #85a270;

  --color-semantic-yellow-900: #221b02;
  --color-semantic-yellow-800: #3a3000;
  --color-semantic-yellow-700: #544600;
  --color-semantic-yellow-600: #725c02;
  --color-semantic-yellow-500: #8e7402;
  --color-semantic-yellow-400: #b28c02;
  --color-semantic-yellow-300: #d6a315;
  --color-semantic-yellow-200: #ffbe11;
  --color-semantic-yellow-150: #ffd216;
  --color-semantic-yellow-100: #ffe07a;
  --color-semantic-yellow-050: #fff0c1;
  --color-semantic-yellow-025: #fff8e0;
  --color-semantic-yellow-sat50: #b59a40;
  --color-semantic-yellow-op07: #fccf56;
  --color-semantic-yellow-sat50-op07: #c9b677;

  --color-red-900: #360f06;
  --color-red-800: #581d0f;
  --color-red-700: #7d2c1a;
  --color-red-600: #a33c25;
  --color-red-500: #cb4d31;
  --color-red-400: #f55f3d;
  --color-red-300: #fe8b70;
  --color-red-200: #feb5a3;
  --color-red-100: #ffdbd1;
  --color-red-050: #ffede8;
  --color-red-025: #fff6f3;
  --color-red-sat50: #c27f70;
  --color-red-op07: #f58d75;
  --color-red-sat50-op07: #d2a399;

  --color-semantic-blue-900: #061d32;
  --color-semantic-blue-800: #0c3252;
  --color-semantic-blue-700: #1a4973;
  --color-semantic-blue-600: #246299;
  --color-semantic-blue-500: #487aaa;
  --color-semantic-blue-400: #6d94bb;
  --color-semantic-blue-300: #90aecd;
  --color-semantic-blue-200: #b4c9df;
  --color-semantic-blue-100: #dae4ef;
  --color-semantic-blue-050: #ecf1f7;
  --color-semantic-blue-025: #f5f8fb;

  --color-chart-blue-base: #035efc;
  --color-chart-blue-sat50: #426fbd;
  --color-chart-blue-op07: #4c8cfb;
  --color-chart-blue-sat50-op07: #4c8cfb;

  --color-chart-purple-base: #6741f1;
  --color-chart-purple-sat50: #426fbd;
  --color-chart-purple-op07: #9278f3;
  --color-chart-purple-sat50-op07: #a69bcf;

  --color-green-quinyx-900: #081e23;
  --color-green-quinyx-800: #12353d;
  --color-green-quinyx-700: #1d4750;
  --color-green-quinyx-600: #3c656c;
  --color-green-quinyx-500: #557d82;
  --color-green-quinyx-400: #699295;
  --color-green-quinyx-300: #93b1b3;
  --color-green-quinyx-200: #b6cacc;
  --color-green-quinyx-100: #dae4e5;
  --color-green-quinyx-050: #edf1f2;
  --color-green-quinyx-025: #f5f8f8;

  --color-zoined-blue: #4fbddb;
  --color-zoined-blue-2: #2aaacd;
  --color-zoined-blue-3: #2492af;

  --color-yellow-axis-950: #181000;
  --color-yellow-axis-850: #302403;
  --color-yellow-axis-750: #4a3908;
  --color-yellow-axis-650: #664f0f;
  --color-yellow-axis-550: #826715;
  --color-yellow-axis-450: #a17f1a;
  --color-yellow-axis-350: #c09921;
  --color-yellow-axis-250: #e1b32a;
  --color-yellow-axis-150: #ffcc33;
  --color-yellow-axis-050: #fff0c4;
  --color-yellow-axis-025: #fef7e6;
}
