.budget-planning {
  zoined-spinner.upload {
    background-color: rgba(0,0,0, 0.2);
    pointer-events: auto;
  }

  .spinner-base {
    position: relative;
    min-height: 100px;
  }

}
