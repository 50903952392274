// Here we have some overrides for Zoined environments, not tied to company style.

// Alpha CSS overrides
body.alpha {
  #zoined-nav {
    .navbar-header {
      a.navbar-brand {
        background-image: url("~@assets/images/logo-alpha.svg");
      }
    }
  }
}

// Beta CSS overrides.
body.beta {
  .ribbon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1111;
    overflow: hidden;
    width: 65px;
    height: 60px;
    text-align: right;
  }
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#60c1e5 0%, #507cb5 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 14px;
    left: -26px;
  }
}

// Demo CSS overrides
body.demo.zoined {
  #zoined-nav {
    .navbar-header {
      a.navbar-brand {
        // On wide screens, show the whole Demo logo.
        @media (min-width: $screen-md-min) {
          width: 265px;
          background-image: url("~@assets/images/logo-demo.svg");
        }
      }
    }
  }
}
