$panel-snippet-box-shadow: 0px 10px 20px rgba(0, 7, 72, 0.05) !default;

html.new-layout {
  // Panel changes (not applying to full screen)
  *:not(.component.full-screen) {
    > .panel-snippet {
      // Smaller horizontal padding
      padding-left: 0;
      padding-right: 0;
      box-shadow: $panel-snippet-box-shadow;

      margin: 0 -15px;
      border-radius: 0;
      @media (min-width: 768px) {
        margin: 0 0;
        border-radius: 10px;
      }

      .abs,
      .rel {
        font-family: Open Sans, sans-serif;
        font-weight: bold;
        font-size: 14px;

        @media (min-width: 992px) {
          font-size: 18px;
        }
      }

      .btn-default:not(.active) {
        background-color: #f4f4f5;
        color: var(--color-black-80);

        &:hover,
        &:focus {
          background-color: #ddd;
        }
      }

      .panel-heading {
        border: none;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 0;

        border-radius: 0;
        @media (min-width: 768px) {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .panel-body {
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: 768px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .panel-footer.show-more {
        border: none;
        background-image: none;

        &:not(.collapsed) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .value {
        font-weight: 800;
        font-size: 32px;
        font-family: Open Sans, sans-serif;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          &.span-3 {
            font-size: 18px;
          }
        }
      }

      .b {
        height: 32px;
        line-height: 32px;
      }

      .small-value {
        line-height: 32px;
      }
    }

    .pagination {
      border-radius: $btn-border-radius-small;
      overflow: hidden;

      input {
        background-color: $pagination-bg;

        &:focus {
          outline: none;
        }
      }

      .page-display:hover {
        input {
          background-color: $pagination-hover-bg;
        }
      }

      > li {
        &:first-child a {
          padding-left: 20px;
        }
        &:last-child a {
          padding-right: 20px;
        }
      }
    }
  }

  .detail-row {
    // Hide box shadow of main component
    position: relative;
    z-index: 2;
    // Hide box shadow blur from top of detail chart container
    .top-placeholder .top-placeholder-bg {
      z-index: 3;
    }
  }

  .is-chart {
    .panel-body {
      // set top padding to zero to counter the spacing in chart
      padding-top: 0;
    }
  }

  .component.detail-open {
    .panel-footer.show-more {
      @media screen {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }

  .snippet-detail {
    .detail-chart-panel {
      padding-top: 10px;
    }
  }

  // Make container fluid with 1170px max-width
  .container {
    @media screen {
      width: 100%;
      max-width: 1170px;
    }
  }

  // Make detail chart full width
  .snippet-chart-wrapper {
    width: 100%;
  }

  // Modal styling
  .modal-content {
    border: none;
    box-shadow: none;

    .modal-header {
      background: transparent;
      color: black;
      border-bottom: none;
      padding-top: 15px;

      .close {
        color: #ccc;
      }

      h4 {
        font-size: 16px;
      }
    }

    h4 {
      font-size: 16px;
    }

    .modal-footer {
      border-top: none;
      padding-bottom: 15px;
    }

    .btn-default:not(.active):not(.btn-outline) {
      background-color: #f4f4f5;
      color: var(--color-black-80);

      &:hover,
      &:focus {
        background-color: #ddd;
      }
    }
  }

  // Dropdowns
  .dropdown-menu {
    border: none;
    font-size: 12px;
    min-width: 80px;
    color: $dropdown-link-color;
    border-radius: var(--dropdown-border-radius);

    .dropdown-header {
      h3 {
        margin-top: 0;
      }
    }

    li > a {
      padding: 5px 10px;
      cursor: pointer;
    }

    li + li {
      border-top: 0;
    }

    .btn-default:not(.active) {
      background-color: #f4f4f5;
      color: var(--color-black-80);

      &:hover,
      &:focus {
        background-color: #ddd;
      }
    }
  }

  // Popover
  .popover {
    border-radius: var(--dropdown-border-radius);
    border: none;
    .popover-title {
      border-top-left-radius: var(--dropdown-border-radius);
      border-top-right-radius: var(--dropdown-border-radius);
      background-color: white;
      padding: 20px 20px 10px 20px;
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
    }
    .popover-content {
      background-color: white;
      padding: 10px 20px 20px 20px !important;
    }
  }

  #toast-container > div {
    opacity: 1;
  }

  #edit-modal,
  #password-modal,
  #email-modal,
  #add-subscription-modal {
    margin-top: var(--header-height);
    z-index: 2147483647;
  }
  .highcharts-tooltip {
    .tooltip-container {
      background-color: black;
    }
    .tooltip-header {
      margin-bottom: 10px;
    }
    table.tooltip-points {
      td {
        vertical-align: middle;
        line-height: 1;
        padding-bottom: 5px;
      }
    }
    .tooltip-legend {
      width: 12px;
      height: 12px;
      border-radius: 3px;
      margin-right: 5px;
    }
    .tooltip-change-positive,
    .tooltip-change-negative {
      width: 12px;
      height: 12px;
      border-radius: 9999px;
      margin: 0 5px 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        display: block;
        border: 3px solid transparent;
      }
    }
    .tooltip-change-positive {
      background-color: $brand-success;
      i {
        border-bottom-color: white;
        transform: translateY(-2px);
      }
    }
    .tooltip-change-negative {
      background-color: $brand-danger;
      i {
        border-top-color: white;
        transform: translateY(2px);
      }
    }
    .tooltip-value {
      color: var(--color-text-primary-inverse);
    }
  }

  .flyover-handle-list {
    .filters {
      .exclude {
        color: #f55;
      }
    }
  }

  .setup-page {
    h3 {
      margin-top: 0;
    }
  }
}
