@font-face {
  font-family: "weather icons";
  src: url("~@assets/fonts/weathericons-regular-webfont.eot");
  src: url("~@assets/fonts/weathericons-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("~@assets/fonts/weathericons-regular-webfont.woff2") format("woff2"),
    url("~@assets/fonts/weathericons-regular-webfont.woff") format("woff"),
    url("~@assets/fonts/weathericons-regular-webfont.ttf") format("truetype"),
    url("~@assets/fonts/weathericons-regular-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
