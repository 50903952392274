/// FILTERS

.menubar {
  margin-top: var(--header-height);
  position: relative;
  z-index: var(--z-index-menubar);
}

///////////////////////////////
///// FILTERS /////////////////
///////////////////////////////

#filters {
  color: var(--color-text-primary-inverse);

  li.btn.pill.sortable-ghost {
    opacity: 0.5;
    .del {
      display: none;
    }
  }

  .structure {
    background-color: transparent;

    li.btn.pill {
      border-radius: 4px;
    }
    // the 'inset' line between time selectors and structure selectors
    .time-structure {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      padding-top: 20px;
      text-align: right;
      .close-button-container {
        @include clearfix();
        margin: 0 0 20px 0;
      }
      .close .fa {
        font-size: 150%;
        line-height: 0.98em;
        vertical-align: -15%;
      }
      .close,
      .export-link {
        text-shadow: none;
        font-size: inherit;
        font-weight: normal;
        color: darken(#fff, 15%);
        &:hover {
          color: darken(#fff, 30%);
          opacity: 1;
        }
        opacity: 1;

        span.text {
          display: none;
          font-size: 85%;
        }
        @media (min-width: $screen-md-min) {
          span.text {
            display: inline-block;
          }
        }
      }

      @media (min-width: $screen-md-min) {
        .export-link {
          display: block;
        }
      }

      .export-link {
        margin-bottom: 10px;
        i {
          margin: 0 0 0 5px;
        }
      }
    }
  }

  .export-link {
    color: #fff;
  }

  .real-filters {
    // lower part
    background-color: var(--color-surface-neutral-6);
    padding: 15px 0;

    // the main buttons on the right (refresh, pdf)
    .buttons {
      text-align: center;

      @media (min-width: $screen-sm-min) {
        text-align: right;
      }

      .btn-wrap {
        padding: 5px 0;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) inset, 0 1px 0 rgba(255, 255, 255, 0.05);

        @media (min-width: $screen-sm-min) {
          &:first-child {
            display: none;
          }
          box-shadow: none;
        }
      }
      .btn {
        @include button-variant(#fff, darken($button-blue, 20%), darken($button-blue, 20%));
        position: relative;
      }
      .refresh-button {
        @include button-variant(#fff, $button-refresh, $button-refresh);
      }
    }
  }

  .edit-button i {
    margin-right: 0.5em;
  }
}

.list-inline > li.title {
  padding-left: 0;
}

// Profile page legacy dialog
//
.ui-dialog {
  z-index: 1000;
  .ui-dialog-titlebar {
    height: 40px;
  }
}

/////////////////////////////////
// TIME SELECTOR DROP-DOWNS /////
/////////////////////////////////

.ui-dialog {
  .dd .dd-panel {
    max-width: 800px;
  }
  .dd .dd-menu,
  .dd .dd-panel {
    background: linear-gradient(to bottom, #3d3d3d 0%, #383838 100%) repeat scroll 0 0 transparent;
    min-width: 225px;
  }
  .dd .dd-menu li > a {
    font-size: 100%;
    color: #ccc;
  }
  .dd .dd-menu li > a:hover {
    background-color: #26282a;
    color: #ccc;
  }
  .ui-widget-content {
    border: none;
    font-family: $copy-font;
  }
  .ui-widget-header {
    margin: 0 10px;
    border: none;
    background: #4bcdf0;
    text-transform: uppercase;
    color: #fff;
  }
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: #fff;
    border: none;
    text-align: center;
  }
  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active {
    background: #4bcdf0;
  }
  .highlight .ui-state-default,
  .ui-widget-content .highlight .ui-state-default,
  .ui-widget-header .highlight .ui-state-default {
    background: #4bcdf0;
  }
  .active .ui-state-default,
  .active .ui-widget-content .ui-state-default,
  .active .ui-widget-header .ui-state-default {
    background: #eeeeec;
  }
}

.info-bubble {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.2);

  &:before {
    font-family: FontAwesome;
    content: "\f05a";
  }
  &:hover {
    color: $zoined-blue;
  }
}

//// SELECTOR BUTTONS (rounded, plus sign, minus sign...)
ul.selector {
  // ul.selector <begins>

  display: inline-block;
  li.btn {
    background-color: var(--color-button-primary-background);
    &:hover {
      background-color: var(--color-button-primary-hover-background);
    }
    &:focus:hover {
      background-color: var(--color-button-primary-active-background);
    }
    position: relative;
    padding: 4px 16px;
    border-radius: 17px;
    font-size: 0.9em;
    font-weight: 400;
    &.exclude {
      @include button-variant(#fff, darken(#e65e43, 0%), darken(#e65e43, 0%));
    }

    &.add,
    &.remove {
      padding: 4px 7px;
    }
    .del {
      position: absolute;
      top: -1px;
      right: -12px;
      display: none;
      width: 1em;
      height: 1em;
      i {
        position: absolute;
        top: 0;
        left: 0;
      }
      .fa-circle.border {
        color: #fff;
        font-size: 70%;
        top: 10%;
        left: 10%;
      }
      .fa-times-circle {
        color: #333436;
      }
      font-size: 170%;
    }
    .del:hover {
      display: block;
      .fa-circle.border {
        color: #333436;
      }
      .fa-times-circle {
        color: darken(#fff, 8%);
      }
    }
    &.focused .del {
      display: inline-block;
    }
    &:hover .del {
      display: inline-block;
    }

    &.deselected,
    &.inactive {
      @include button-variant(#73726e, #a4a29c, #a4a29c);
    }
  }
}
// ul.selector <ends>
