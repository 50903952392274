zoined-filter {
  ul.selector {
    li.pill.inactive {
      display: none;
    }
    li.pill.btn.inactive {
      display: inherit;
    }
  }
}
