//////////////////////////////
// NEWSLETTER DEFINITIONS ////
//////////////////////////////

.newsletter-definition-editor {
  position: relative;
  min-height: 300px;

  // Make checkbox label appear disabled, when the checkbox itself is disabled.
  .checkbox {
    input[type="checkbox"][disabled] + label,
    input[type="checkbox"][disabled] + span {
      color: #999999;
      cursor: not-allowed;
    }
  }

  // 1411 - Newsletter editor save button is underneath the support button.
  .row.buttons-row .col-md-12.margin-bottom-fix-50 {
    margin-bottom: 50px;
  }

  .modal-body {
    .content {
      min-height: 200px;
    }
  }
  @media (max-width: 414px) {
    #edit-insights-modal {
      &.modal {
        .modal-dialog {
          margin: 0;
          width: 100%;
          margin-bottom: 10rem;
          .modal-content {
            border: none;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.newsletter-definition .sections .section {
  background: white;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;

  .thumbnail {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
      width: 210px;
      vertical-align: top;
    }
  }
  .configuration {
    flex: 1;
    padding: 0 20px;
    width: 100%;
  }

  .option {
    display: flex;
    margin-bottom: 10px;
    .checkbox {
      display: inline-block;
      @media (max-width: 499px) {
        display: block;
        clear: left;
      } // 1413
      vertical-align: top;
      margin: 0;
    }

    zoined-like-for-like-filter {
      .title {
        display: none;
      }
    }
  }

  zoined-i18n,
  .option > label {
    width: 200px;
    padding: 5px 15px 0 0;
    text-align: right;
    @media (max-width: 499px) {
      text-align: left;
      clear: left;
    } // 1413
    display: block;
    font-weight: normal;
    margin: 0;
  }

  button > zoined-i18n {
    min-width: 10px;
    padding-right: 0;
    text-align: center;
    //display: inline-block;
  }

  .option zoined-filter {
    display: block !important;
    padding: 3px 0 0 200px;
    @media (max-width: 499px) {
      padding: 3px 0 0 0;
    } // 1413
    width: 100%;
  }

  zoined-filter li.title {
    font-weight: bold;
  }
}

.buttons-row {
  margin-top: 20px;
  margin-bottom: 30px;

  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.modal-body .section-thumbnail {
  display: table;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  &:hover {
    background-color: #eeeeee;
  }
  .image {
    display: table-cell;
    width: 200px;
  }
  .caption {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top;
    h3 {
      margin-top: 0px;
      font-weight: normal;
    }
  }
}

@mixin embedded-filters {
  width: 100%;
  .selectors {
    background: inherit !important;
    li.title {
      color: #4d4d4d;
    }
  }
}
.newsletter-filters {
  @include embedded-filters;
}
.admin-filter {
  @include embedded-filters;
  margin: 15px 0 0 0;
}
.section-filters {
  margin-top: 10px;
}

.newsletter-preview-modal {
  .preview-content {
    // Will be overridded when iframe is ready
    width: 640px;
    height: 0px;
    transition: height 0.2s;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    iframe {
      border: none;
      width: 100%;
      height: 99%;
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .modal-body {
    position: relative;
    min-height: 200px;
  }

  .modal-dialog {
    @media (min-width: $screen-sm-min) {
      width: 660px;
    }
  }
}

.section-type-image {
  width: 200px;
  &.top {
    background-image: url("~@assets/images/sections/top.png");
    height: 106px;
  }
  &.metric_comparison {
    background-image: url("~@assets/images/sections/metric_comparison.png");
    height: 80px;
  }
  &.metric_extremes {
    background-image: url("~@assets/images/sections/metric_extremes.png");
    height: 60px;
  }
  &.time_series {
    background-image: url("~@assets/images/sections/time_series.png");
    height: 135px;
  }
  &.report_attachment {
    background-image: url("~@assets/images/sections/Microsoft_Excel_2013_logo.png");
    height: 50px;
    background-size: 50px;
    background-repeat: no-repeat;
  }
  &.pdf_attachment {
    background-image: url("~@assets/images/sections/icon_PDF.png");
    height: 50px;
    background-size: 40px 50px;
    background-repeat: no-repeat;
  }
  &.sok_provision {
    background-image: url("~@assets/images/sections/sok_provision.png");
    height: 124px;
  }
}
