zoined-calendar {

  // Map glyphicons to FontAwesome icons.
  .glyphicon {
    @extend .fa;
    // Overloading "glyphicon-chevron-left" with "fa-arrow-left".
    &.glyphicon-chevron-left {
        @extend .fa-chevron-left;
    }
    // Overloading "glyphicon-chevron-right" with "fa-arrow-right".
    &.glyphicon-chevron-right {
        @extend .fa-chevron-right;
    }
  }

  .uib-day, .uib-month, .uib-year {

    // Today, this month, this year.
    .btn-info {
      background: $calendar-current-bg;
    }
    .text-info {
      font-weight: bold;
      color: $calendar-current-fg;
    }

    // Active and selected (last clicked).
    .btn.active {
      background: $btn-default-bg;
      color: $btn-default-color;
      border-color: $btn-default-border;
      box-shadow: none;
    }

    // Selected.
    &.selected .btn {
      background: $calendar-selected-bg;
      color: $calendar-selected-fg;
    }
  }
}
