.panel-snippet .panel-body .snippet table.data-table {
  thead {
    tr {
      th {
        border: 1px solid #cecece;
        text-align: center;
      }
    }
  }
}
