.smcx-modal-close {
    background: #81E4FF url(https://secure.surveymonkey.com/assets/responseweb/responseweb/0.272.2/assets/images/website/x.png) 2px 2px scroll no-repeat!important;
    background: #FFF!important;
    color:#000;
    padding: 2px 4px;
    font-weight: bold;
    text-align: center;
    &::after {
        content: "X";
    }
}