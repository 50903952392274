@mixin modal-fullscreen() {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0;
  }
}

.modal-fullscreen-xs {
  @media (max-width: $grid-float-breakpoint) {
    @include modal-fullscreen;
  }
}

.company-admin,
.partner-admin,
.zoined-admin {
  .table-controls {
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}
