.color-muted {
  color: var(--color-muted);
}
.color-black-80 {
  color: var(--color-black-80);
}
.bg-transparent {
  background-color: transparent;
}
.bg-card {
  background-color: white;
}
.bg-surface-neutral-no-contrast {
  background-color: var(--color-surface-neutral-no-contrast);
}
.bg-surface-neutral-1 {
  background-color: var(--color-surface-neutral-1);
}
.bg-surface-neutral-2 {
  background-color: var(--color-surface-neutral-2);
}
.bg-surface-emphasis-primary-1 {
  background-color: var(--color-surface-emphasis-primary-1);
}
.bg-interactive-bg-emphasis-1 {
  background-color: var(--color-interactive-bg-emphasis-1);
}
.bg-interactive-bg-emphasis-2 {
  background-color: var(--color-interactive-bg-emphasis-2);
}
.bg-interactive-bg-disabled {
  background-color: var(--color-interactive-bg-disabled);
}
.bg-surface-emphasis-primary-1 {
  background-color: var(--color-surface-emphasis-primary-1);
}
.bg-text-primary {
  background-color: var(--color-text-primary);
}
.bg-text-emphasis {
  background-color: var(--color-text-emphasis);
}
.bg-semantic-error {
  background-color: var(--color-semantic-error);
}
.bg-border-variant-2 {
  background-color: var(--color-border-variant-2);
}
.fg-text-primary {
  color: var(--color-text-primary);
}
.fg-text-emphasis {
  color: var(--color-text-emphasis);
}
.fg-text-variant {
  color: var(--color-text-variant);
}
.fg-text-secondary {
  color: var(--color-text-secondary);
}
.fg-border-variant {
  color: var(--color-border-variant);
}
.fg-text-primary-inverse {
  color: var(--color-text-primary-inverse);
}
.fg-text-disabled {
  color: var(--color-text-disabled);
}
.fg-semantic-success {
  color: var(--color-semantic-success);
}
.fg-semantic-error {
  color: var(--color-semantic-error);
}
.fg-semantic-negative-text {
  color: var(--color-semantic-negative-text);
}
