$successColor: #4bcdf0;
$errorColor: #e3000f;

.file-drop {
  position: relative;
  width: 400px;
  padding: 30px;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  background: #f5f5f5;

  cursor: pointer;

  border: 5px dashed #ccc;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
