////////////////////////////////////
///// VARIABLES ////////////////////
////////////////////////////////////

$screen-xl-min: 1500px;

$gray-darker: lighten(#000, 13.5%) !default; // #222
$gray-dark: lighten(#000, 20%) !default; // #333
$gray: lighten(#000, 33.5%) !default; // #555
$gray-light: lighten(#000, 60%) !default; // #999
$gray-lighter: lighten(#000, 93.5%) !default; // #eee

/* **** COLORS ******** */
$zoined-blue: rgba(75, 205, 240, 1) !default; // #4bcdf0
$zoined-red: rgba(250, 100, 65, 1) !default; // #fa6441
$zoined-red-darker: #e54e2e;
$zoined-green: rgba(133, 194, 88, 1); // #85c258
$zoined-yellow: rgba(255, 190, 15, 1) !default; // #ffbe0f
$button-blue: #4fbddb;
$button-refresh: #85c258;
$navbar-link: #e6e6e6;
$navbar-link-hover: #fff;
$navbar-link-active: #fff;
$min-width: 320px;
$brand-primary: $zoined-blue;
$brand-success: $zoined-green;
$brand-warning: $zoined-red;
$brand-danger: #d9534f;
$brand-info: $zoined-yellow;
$background-color: #ededed !default;

$btn-showmore-color: darken(#fff, 10%);
$btn-showmore-bg: #393939;
$btn-showmore-border: darken($btn-showmore-bg, 5%);
$panel-snippet-bg: #fff !default;
$panel-snippet-body-padding: 15px 0px 15px 0px !default;
$panel-snippet-heading-padding: 20px 0 10px 0 !default;
$panel-snippet-footer-padding: 2px 0;
$panel-snippet-border-radius: 0px !default;
//$border-radius-base !default;
//* * Border color for elements within panels
$panel-snippet-inner-border: rgba(0, 0, 0, 0.05);
$panel-snippet-footer-bg: #fff;
//#f5f5f5 !default;
$panel-snippet-text: $gray-dark !default;
$panel-snippet-border: rgba(0, 0, 0, 0.05);
$panel-snippet-heading-bg: #fff;
//#f5f5f5 !default;
$copy-font: Open Sans, sans-serif;
$display-font: Oswald, sans-serif;

// Name colors by function.
$underline-accent: $zoined-blue;
$link-hover: $zoined-blue;
$good-change: $zoined-green;
$bad-change: $zoined-red;
$dashboard-legend: lighten(#000, 20%) !default; // #333
$calendar-current-bg: white;
$calendar-current-fg: $zoined-red;
$calendar-selected-bg: $zoined-blue;
$calendar-selected-fg: white;
$calendar-selected-border: $zoined-red;
