.selectors.pill-select {

  ul.dropdown-menu {
    padding: 2px;
    li {
      position: relative;

      a {
        padding-left: 10px;
      }

      a[disabled] {
        color: #ccc;
      }

      a.add {
        i.delete {
          color: #333;
          display: none;
          position: absolute;
          margin: 0px;
          padding: 6px 0px 0px 0px;
          top: 0px;
          right: 0px;
        }
      }
      a.add:hover {
        i.delete {
          display: block;
        }
        i.delete:hover {
          font-weight: bold;
        }
      }

      &.header {
        padding: 10px 10px 0px 10px;
        text-transform: uppercase;
        font-size: 12px;
        color: #999999;
      }

    }
    li.header:last-child {
      padding-bottom: 10px;
    }
  }
}
