.highcharts-annotation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .selection-value {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-primary);
  }

  .comparison-value,
  .absolute-change {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text-variant);
  }

  .percentual-change {
    margin-top: 5px;
    display: flex;
    align-items: center;
    i.fa {
      font-size: 16px;
      line-height: 1;
      margin-right: 5px;
    }
    font-size: 12px;
    font-weight: 700;
  }
}
.highcharts-tooltip {
  .tooltip-container {
    padding: 8px;
    background-color: white;
    border-radius: 4px;
  }

  .tooltip-values-horizontal {
    display: flex;
    gap: 20px;
  }

  .tooltip-value {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-primary);
  }

  .tooltip-value-band {
    height: 20px;
    width: 3px;
  }

  .tooltip-changes-horizontal {
    margin-top: 10px;
    display: flex;
    gap: 20px;
  }

  .tooltip-change {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    gap: 10px;

    .percentual-change {
      display: flex;
      align-items: center;
      gap: 5px;

      i.fa {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}

.highcharts-legend-item {
  padding: 1em;

  > span {
    position: relative;
    overflow: visible !important;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 45px);
      border: 1px solid var(--color-border-variant-2);
      height: 25px;
      top: -5px;
      left: -30px;
      border-radius: 50px;
      transition: all 0.2s;
    }

    &:hover {
      &::after {
        border-color: var(--color-border-variant);
      }
    }

    &:active {
      &::after {
        border-color: var(--color-border-variant-2);
      }
    }
  }
}

.highcharts-axis {
  .highcharts-axis-title,
  .highcharts-axis-title > div {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
