// Footer
.shared-footer {
  a:hover {
    text-decoration: underline;
  }
}

.simple-spinner {
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100px;

  .spinner {
    animation: spinner-rotate 2s linear infinite;
    &:before {
      color: #ccc;
      font-family: FontAwesome;
      font-size: 30pt;
      content: "\f013";
    }
  }

  .timeout {
    &:before {
      color: $brand-warning;
      font-family: FontAwesome;
      font-size: 30pt;
      content: "\f06a";
    }
  }

  .error {
    &:before {
      color: $brand-warning;
      font-family: FontAwesome;
      font-size: 30pt;
      content: "\f071";
    }
  }
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.triangle {
  border: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  vertical-align: middle;
}
.triangle.down {
  border-top-color: #ffffff;
  margin-top: 4px;
}
.triangle.up {
  border-bottom-color: #ffffff;
  margin-top: -7px;
}

/* Login pages */
h2.block-title {
  font-family: Oswald, sans-serif !important;
  text-transform: uppercase;
  border-bottom: 2px solid $underline-accent;
  padding-bottom: 2px;
  margin-bottom: 15px;
}

.well {
  a:not(.btn) {
    color: $button-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}

form .profile-actions {
  border-top: 1px solid #cccccc;
  padding: 2em 0;
}
.dd .dd-menu .search .no-results {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: none; // Overridden with javascript
}

.dd .dd-menu ul.scrollable {
  position: relative;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 0px;

  li > a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $gray;
  }
  li.header {
    text-transform: uppercase;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: #999999;
  }
}
.dd .dd-menu.reports {
  max-width: 230px;
}
.dd .dd-menu.reports li > a {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  white-space: normal;
  border-bottom: 1px solid #cdcdc6;
}
.dd .dd-menu.reports li:last-child > a {
  border-bottom: none;
}
.dd .dd-menu.reports li > a:hover,
.dd .dd-menu.reports label:hover {
  background: #f6f6f5;
}
.dd.user .dd-menu,
.dd.user .dd-panel,
.dd.company .dd-menu,
.dd.company .dd-panel {
  border: none;
  background: #e5e5e5;
}
.dd.company .dd-menu li > a,
.dd.company .dd-panel li > a {
  color: #4d4d4d;
}
.dd.company .dd-menu li > a:hover,
.dd.company .dd-panel li > a:hover {
  color: #fff;
}
.dd.user .dd-panel {
  min-width: 270px;
}
.user .apply-row {
  margin-top: 20px;
  padding: 20px 0 10px;
  background: #e5e5e5;
}
.user .avatar {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 10px;
  background-size: 70px 70px;
}
.large.avatar {
  width: 110px;
  height: 110px;
  background-size: 110px 110px;
  margin-bottom: 20px;
}
.user .title {
  font-weight: bold;
}
.user p {
  margin: 0;
  line-height: 1.5;
  font-size: 80%;
}

/* Overriding jquery dropdown styles */
.dd .dd-menu li > a {
  font-size: 100%;
  color: #4d4d4d;
}

/* Overriding jquery dropdown styles */
.search-row {
  background: #fff;
  color: #000;
  padding: 10px 5px;
}
.search-row input {
  width: 96%;
  background: #eeeeec;
  padding: 5px 2px;
  font-weight: bold !important;
}
.search-row p {
  font-weight: 700;
  margin-bottom: 0;
}
.apply-row {
  background: #fff;
  text-align: center;
  padding: 5px 0;
  border-top: 1px solid #cdcdc6;
}
.dd .filter .dd-menu,
.dd .filter .dd-panel {
  border-radius: 0;
}

.large-icon {
  display: inline-block;
  width: 180px;
  height: 130px;
  background: no-repeat;
  background-size: 180px 130px;
  background-image: none;
  &.group-customers {
    background-image: url("~@assets/images/group-customers.png");
  }
  &.group-assortment {
    background-image: url("~@assets/images/group-assortment.png");
  }
  &.group-organization {
    background-image: url("~@assets/images/group-organization.png");
  }
  &.group-demand {
    background-image: url("~@assets/images/group-demand.png");
  }
  &.group-people_counting {
    background-image: url("~@assets/images/group-people_counting.png");
  }
  &.group-occupancy {
    background-image: url("~@assets/images/group-occupancy.png");
  }
  &.group-parking {
    background-image: url("~@assets/images/group-parking.png");
  }
  &.group-demographics {
    background-image: url("~@assets/images/group-demographics.png");
  }
  &.group-my-reports,
  &.group-user_dashboard-reports {
    background-image: url("~@assets/images/group-my-reports.png");
  }
  &.group-company-reports,
  &.group-company_dashboard-reports {
    background-image: url("~@assets/images/group-company-reports.png");
  }
  &.group-other-reports,
  &.group-other_dashboard-reports {
    background-image: url("~@assets/images/group-other-reports.png");
  }
  &.group-workhours {
    background-image: url("~@assets/images/group-workhours-reports.png");
  }
}

//////////////////////////////////////
///// MEDIA QUERIES //////////////////
//////////////////////////////////////

@media (max-width: 767px) {
  .fly-over-footer {
    margin-bottom: 30px;
    text-align: center;
  }
  .bottom-links {
    bottom: 0;
  }
}

/* Hi-res images */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 1.5/1),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 96dpi),
  only screen and (min-resolution: 1.5dppx) {
  .brand a {
    background-image: url("~@assets/images/logo.svg");
  }
  .menu-button {
    background-image: url("~@assets/images/menu.svg");
  }
  .user .avatar {
  }
  .mobile .user .avatar,
  .large.avatar {
  }
}

/* Profile Starts */
#company-list-profile,
#dp-company-list-profile {
  left: 14px !important;
  top: 100px !important;
}
#default-params .selectors,
#user-rights .selectors {
  background-color: #ffffff !important;
}

/* Dashbord::Filter::typeahead */
.twitter-typeahead .tt-hint {
  color: #aaaaaa;
}
.tt-suggestion {
  font-weight: normal !important;
  font-size: 12px;
}
.tt-suggestion p {
  font-weight: normal !important;
}

.dd-menu {
  .search-icon {
    display: inline-block;
  }
  .spin-icon {
    display: none;
  }
  .input-group .form-control {
    float: none;
  }
  &.loading {
    .search-icon {
      display: none;
    }
    .spin-icon {
      display: inline-block;
    }
  }
}

/* Dashbord::Filter::typeahead */
.snippet {
  h4 {
    display: none;
  }
}
p.chart-error {
  color: #222;
  text-align: center;
  font-size: 1.5em;
  line-height: 48px;
  position: relative;
  z-index: 11;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

@media screen {
  .screen-nowrap {
    white-space: nowrap;
  }
}
