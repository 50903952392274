zoined-chart {
  display: block;
  height: 100%;
  .chart-element {
    height: 100%;
  }
}

zoined-snippet {
  display: block;
  position: relative;
}

zoined-block {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  .paginated-block, .trend-block {
    height: 100%;
    padding-bottom: 30px;
    .graph-pagination {
      height: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
