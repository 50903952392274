@import "vars/color-palette.scss";
@import "vars/color-tokens.scss";

body {
  --screen-sm-min: #{$screen-sm-min};
  --screen-md-min: #{$screen-md-min};
  --screen-lg-min: #{$screen-lg-min};

  // colors
  --color-primary: #{$brand-primary};
  --color-yellow: #{$zoined-yellow};

  --color-black-95: #1a1a1a;
  --color-black-90: #2e2e2f;
  --color-black-80: #3d3d3d;
  --color-black-60: #474747;
  --color-muted: #84827d;
  --color-background: #{$background-color};

  --color-zoined-red: #{$zoined-red};
  --color-zoined-red-darker: #{$zoined-red-darker};

  --color-sidenav-background: var(--color-surface-neutral-max-contrast);
  --color-sidenav-text: var(--color-text-primary-inverse);
  --color-sidenav-category-title: var(--color-text-emphasis-on-dark);
  --color-sidenav-report-active-background: rgba(75, 205, 240, 0.2);
  --color-sidenav-report-active-text: white;
  --color-sidenav-active-text: white;
  --color-sidenav-icon-btn-background: transparent;
  --color-sidenav-icon-btn-border: var(--color-border-emphasis-on-dark);
  --color-sidenav-icon-btn-text: var(--color-text-emphasis-on-dark);
  --color-sidenav-icon-btn-hover-background: var(--color-interactive-fill-primary-on-dark);
  --color-sidenav-icon-btn-hover-text: var(--color-text-button-primary);
  --color-sidenav-icon-btn-active-background: var(--color-interactive-fill-primary-on-dark);
  --color-sidenav-icon-btn-active-text: var(--color-text-button-primary);
  --color-sidenav-search-box-background: transparent;
  --color-sidenav-search-box-border: var(--color-border-primary);
  --color-sidenav-search-box-text: var(--color-text-primary-inverse);

  --color-button-primary-background: var(--color-interactive-fill-primary);
  --color-button-primary-text: var(--color-text-button-primary);
  --color-button-primary-hover-background: var(--color-interactive-fill-primary-variant);
  --color-button-primary-hover-text: var(--color-text-button-primary);
  --color-button-primary-active-background: var(--color-interactive-fill-primary);
  --color-button-primary-active-text: var(--color-text-button-primary);
  --color-button-primary-disabled-background: var(--color-interactive-fill-disabled);
  --color-button-primary-disabled-text: var(--color-text-disabled);
  --color-button-primary-inverted-border: var(--color-border-emphasis);
  --color-button-primary-inverted-text: var(--color-text-emphasis);
  --color-button-primary-inverted-hover-background: var(--color-interactive-fill-primary);
  --color-button-primary-inverted-hover-text: var(--color-text-button-primary);
  --color-button-primary-inverted-active-background: var(--color-interactive-fill-primary-variant);
  --color-button-primary-inverted-active-text: var(--color-text-button-primary);
  --color-button-primary-inverted-disabled-border: var(--color-border-secondary);
  --color-button-primary-inverted-disabled-text: var(--color-text-disabled);
  --color-button-multiple-background: var(--color-interactive-fill-neutral-low-contrast);
  --color-button-multiple-text: var(--color-text-variant);
  --color-button-negative-background: var(--color-semantic-negative-surface);
  --color-button-negative-text: var(--color-text-primary-inverse);

  .dark-bg {
    --color-button-primary-background: var(--color-interactive-fill-primary-on-dark);
    --color-button-primary-hover-background: var(--color-interactive-fill-primary-variant-on-dark);
    --color-button-primary-active-background: var(--color-interactive-fill-primary-on-dark);
    --color-button-primary-disabled-background: var(--color-interactive-fill-disabled-on-dark);
    --color-button-primary-disabled-text: var(--color-text-disabled-on-dark);
    --color-button-primary-inverted-border: var(--color-border-emphasis-on-dark);
    --color-button-primary-inverted-text: var(--color-text-emphasis-on-dark);
    --color-button-primary-inverted-hover-background: var(--color-interactive-fill-primary-on-dark);
    --color-button-primary-inverted-active-background: var(--color-interactive-fill-primary-variant-on-dark);
    --color-button-multiple-background: var(--color-interactive-fill-neutral-high-contrast);
    --color-button-multiple-text: var(--color-text-primary-inverse);
    --color-button-negative-background: var(--color-semantic-negative-surface-on-dark);
    --color-button-primary-inverted-disabled-border: var(--color-border-disabled-on-dark);
    --color-button-primary-inverted-disabled-text: var(--color-text-disabled-on-dark);
  }

  --color-surface-emphasis-primary-1: #effaff;
  --color-semantic-success: #85c258;
  --color-semantic-error: #ef6345;
  --color-border-variant-2: #dadad9;
  --color-interactive-bg-emphasis: #ffbe11;
  --color-interactive-bg-emphasis-light: #ffd216;
  --color-interactive-bg-emphasis-dark: #d6a315;
  --color-interactive-bg-emphasis-1: #dff4ff;
  --color-interactive-bg-emphasis-2: #2aaacd;
  --color-interactive-bg-emphasis-4: #359cb8;
  --color-interactive-bg-emphasis-5: #17819c;
  --color-interactive-bg-emphasis-6: #0e4d5d;
  --color-interactive-bg-disabled: #d4d4d4;

  --border-radius-sm: #{$border-radius-small};
  --border-radius-md: #{$border-radius-base};
  --border-radius-lg: #{$border-radius-large};

  --elevation-md: 0px 4px 20px 0px rgba(218, 218, 217, 0.5);
  --elevation-sm: 0px 2px 6px rgba(0, 0, 0, 0.15);
  --elevation-inline: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  --spacing-md: 10px;

  --z-index-menubar: 800;
  --z-index-dropdown: 850;
  --z-index-topbar: 900;
  --z-index-ai-assistant: 950;
  --z-index-product-fruits: 950;
  --z-index-sidenav-overlay: 990;
  --z-index-sidenav: 991;
  --z-index-overlay: 1000;
  --z-index-flyover-filters: 1200;

  #zoined-nav {
    --z-index-dropdown: 1055;
  }

  @media (min-width: $grid-float-breakpoint) {
    // On desktop menubar comes on top of topbar
    --z-index-menubar: 910;
    --z-index-flyover-filters: 100;
  }

  --sidenav-iconbar-width: 60px;
  --sidenav-navbar-width: 260px;
  --sidenav-expanded-width: calc(var(--sidenav-iconbar-width) + var(--sidenav-navbar-width));
  --header-height: 50px;
  --ai-assistant-width: 300px;
  --menubar-height: 50px;

  @media (min-width: $grid-float-breakpoint) {
    --sidenav-iconbar-width: 82px;
    --header-height: 90px;
  }

  #app.simple-page {
    --header-height: 62px;
  }

  @media (min-width: 1200px) {
    --ai-assistant-width: 360px;
  }

  @media (min-width: 1400px) {
    --ai-assistant-width: 400px;
  }

  --navbar-dropdown-border-radius: 20px;
  --navbar-dropdown-background-color: white;
  --navbar-dropdown-border-color: white;
  --navbar-dropdown-hover-bg: #eee;
  --navbar-dropdown-hover-border: #eee;

  --panel-snippet-border-radius: #{$panel-snippet-border-radius};
  --dropdown-border-radius: #{$border-radius-base};
}
