zoined-range-filter {
  
    display: inline-block;
    
    ul.selector {
      display: inline-block;
    }
  
    ul.selector:not(:last-child) {
      margin-right: 0px;
    }
    

    .filter-form {
      padding: 6px;
      
      .filter-form-title {
        padding-bottom: 10px;
      }

      .filter-form-buttons {
        padding-top: 10px;
        text-align: right;

        .btn + .btn {
          margin-left: 5px;
        }
      }
    }

    .dropdown-menu {
      color: #333;
      padding: 4px;
      min-width: 348px;
      max-width: 360px;

      .search-control {
        width: 338px;
      }

      ul.items {
        list-style: none;
        padding: 0;
        position: relative;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;

        li {
          margin: 0;
        }

        li.category-header {
          padding: 10px 10px 0 10px,
        }

        li a {
          padding: 7px 10px;
          border-radius: 5px;

          &:hover {
            background-color: #4bcdf0;
            color: #FFF;
            cursor: pointer;
          }
        }

        .category-header {
          text-transform: uppercase;
          padding: 10px;
          font-size: 12px;
          color: #999999;
        }
      }
      
      a {
        white-space: initial;
      }
  
      a:focus:not(:hover) {
        background-color: inherit;
      }
  
      .custom {
        padding: 5px 20px;
      }
    }
  }