/* Axis styles */

@import "./zoined";

body.axis {
  --logo-url: url("~@assets/images/axis/axis-logo-white.png");
  --logo-img-url: url("~@assets/images/axis/axis-pyramid.png");
  --color-primary: var(--color-yellow-axis-150);

  --color-text-button-primary: var(--color-text-primary);

  --color-text-emphasis: var(--color-yellow-axis-550);
  --color-text-emphasis-on-dark: var(--color-yellow-axis-150);
  --color-text-emphasis-variant: var(--color-yellow-axis-650);
  --color-text-emphasis-variant-on-dark: var(--color-yellow-axis-050);
  --color-text-emphasis-on-emphasis: var(--color-yellow-axis-550);

  --color-border-emphasis: var(--color-yellow-axis-550);
  --color-border-emphasis-on-dark: var(--color-yellow-axis-150);

  --color-interactive-fill-primary: var(--color-yellow-axis-150);
  --color-interactive-fill-primary-on-dark: var(--color-yellow-axis-150);
  --color-interactive-fill-primary-variant: var(--color-yellow-axis-250);
  --color-interactive-fill-primary-variant-on-dark: var(--color-yellow-axis-050);

  --color-sidenav-icon-btn-background: transparent;
  --color-sidenav-icon-btn-border: var(--color-border-emphasis-on-dark);
  --color-sidenav-icon-btn-text: var(--color-text-emphasis-on-dark);
  --color-sidenav-icon-btn-hover-background: var(--color-interactive-fill-primary-on-dark);
  --color-sidenav-icon-btn-hover-text: var(--color-text-button-primary);
  --color-sidenav-icon-btn-active-background: var(--color-interactive-fill-primary-on-dark);
  --color-sidenav-icon-btn-active-text: var(--color-text-button-primary);

  --color-sidenav-search-box-background: transparent;
  --color-sidenav-search-box-border: var(--color-border-primary);
  --color-sidenav-search-box-text: var(--color-text-primary-inverse);

  #sidenav {
    .logo-container {
      a {
        width: 38px;
        height: 38px;
        background-image: var(--logo-img-url);
        background-size: 38px auto;
        background-repeat: no-repeat;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s;

        .zoined-logo {
          display: none;
        }
      }
    }
    .results-logo-container {
      width: 100%;
      height: 46px;
      background-image: var(--logo-url);
      background-size: auto 40px;
      background-repeat: no-repeat;
      background-position-y: center;
      opacity: 0;
      transition: opacity 0.25s;

      .zoined-logo {
        display: none;
      }
    }

    &.open,
    &.expanded {
      .logo-container a {
        opacity: 0;
      }
      .results-logo-container {
        opacity: 1;
      }
    }
  }

  nav#zoined-nav {
    .navbar-header {
      .navbar-brand {
        background-image: var(--logo-img-url);
        background-size: 30px auto;
        background-position: center;
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
      }
    }
  }
}
