.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.empty-hidden {
  &:empty {
    display: none;
  }
}

.print-hidden {
  @media print {
    display: none !important;
  }
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.bottom-0 {
  bottom: 0;
}

.translate-y-1\/2 {
  transform: translateY(50%);
}

.translate-x-1\/2 {
  transform: translateX(50%);
}

.\-translate-y-1\/2 {
  transform: translateY(-50%);
}

.\-translate-x-1\/2 {
  transform: translateX(-50%);
}

.\-translate-1\/2 {
  transform: translate(-50%, -50%);
}

.inline-block {
  display: inline-block;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-inline-row {
  display: inline-flex;
  flex-direction: row;
}

.sm-flex-row {
  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-direction: row;
  }
}

.md-flex-row {
  @media (min-width: $screen-md-min) {
    display: flex;
    flex-direction: row;
  }
}

.print-flex-row {
  @media print {
    display: flex !important;
    flex-direction: row;
  }
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.sm-align-items-center {
  @media (min-width: $screen-sm-min) {
    align-items: center;
  }
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-flex-start {
  align-self: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-center-except-xs {
  @media (min-width: $screen-sm-min) {
    justify-content: center;
  }
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.min-h-0 {
  min-height: 0;
}

.min-w-0 {
  min-width: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-sm {
  margin-top: 5px;
}

.mt-md {
  margin-top: 10px;
}

.mt-lg {
  margin-top: 15px;
}

.mt-xl {
  margin-top: 20px;
}

.mb-sm {
  margin-bottom: 5px;
}

.mb-md {
  margin-bottom: 10px;
}

.mb-lg {
  margin-bottom: 15px;
}

.mb-xl {
  margin-bottom: 20px;
}

.mr-md {
  margin-right: 10px;
}

.mr-sm {
  margin-right: 5px;
}

.mr-xl {
  margin-right: 20px;
}

.mr-xxl {
  margin-right: 30px;
}

.ml-md {
  margin-left: 10px;
}

.ml-sm {
  margin-left: 5px;
}

.ml-xl {
  margin-left: 20px;
}

.ml-xxl {
  margin-left: 30px;
}

.my-md {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-sm {
  margin: 5px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p-sm {
  padding: 5px;
}

.p-md {
  padding: 10px;
}

.p-lg {
  padding: 15px;
}

.px-xl {
  padding-left: 30px;
  padding-right: 30px;
}

.px-lg {
  padding-left: 15px;
  padding-right: 15px;
}

.px-md {
  padding-left: 10px;
  padding-right: 10px;
}

.px-sm {
  padding-left: 5px;
  padding-right: 5px;
}

.py-lg {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-md {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pl-lg {
  padding-left: 15px;
}

.pr-lg {
  padding-right: 15px;
}

.pt-sm {
  padding-top: 5px;
}

.pt-md {
  padding-top: 10px;
}

.pt-lg {
  padding-top: 15px;
}

.pt-xl {
  padding-top: 20px;
}

.pb-md {
  padding-bottom: 10px;
}

.pb-lg {
  padding-bottom: 15px;
}

.pl-xxl {
  padding-left: 30px;
}

.pr-xxl {
  padding-right: 30px;
}

.md-p-0 {
  @media (min-width: $screen-md-min) {
    padding: 0;
  }
}

.rounded {
  border-radius: var(--border-radius-md);
}

.rounded-tl {
  border-top-left-radius: var(--border-radius-md);
}

.rounded-tr {
  border-top-right-radius: var(--border-radius-md);
}

.rounded-sm {
  border-radius: var(--border-radius-sm);
}

.rounded-full {
  border-radius: 9999px;
}

.uppercase {
  text-transform: uppercase;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-5 {
  font-size: 20px;
}

.text-base {
  font-size: 16px;
}

.text-underline {
  text-decoration: underline;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.valign-top {
  vertical-align: top;
}

.nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.gap-sm {
  gap: 5px;
}

.gap-md {
  gap: 10px;
}

.gap-lg {
  gap: 15px;
}

.gap-xl {
  gap: 20px;
}

.gap-xxl {
  gap: 30px;
}

.column-gap-xl {
  column-gap: 20px;
}

.column-gap-xxl {
  column-gap: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.fa-sm {
  font-size: 10px;
}

.border-b-solid {
  border-bottom: 1px solid var(--color-text-primary);
}

.border-b-dash {
  border-bottom: 1px dashed var(--color-text-primary);
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.line-clamp-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
