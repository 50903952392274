.btn {
  height: 34px;
  line-height: 22px;
  padding: 5px 20px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.2s;
  outline: none !important;

  &.btn-icon {
    padding: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  &.btn-sm {
    height: 30px;
    line-height: 18px;
    padding: 5px 10px;

    &.btn-icon {
      width: 30px;
      padding: 0;
    }
  }

  &.btn-xs {
    height: 22px;
    line-height: 18px;
    padding: 2px 12px;

    &.btn-icon {
      height: 24px;
      width: 24px;
      padding: 0;
    }
  }
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.btn.btn-danger {
  background-color: $zoined-red;
}
.btn-rect {
  border-radius: 4px;
}

.btn.btn-default {
  border: none;

  &[disabled] {
    opacity: 1;
    color: var(--color-muted);
  }

  &:hover {
    background-color: #eee;
  }

  &.active {
    box-shadow: none;
    background-color: var(--color-black-60);
    color: white;
  }

  &.btn-outline {
    border: 1px solid #eee;
  }
}

.btn-primary {
  &,
  &:focus {
    background-color: var(--color-button-primary-background);
    border-color: var(--color-button-primary-background);
    color: var(--color-button-primary-text);
  }
  &:hover {
    &,
    &:focus {
      background-color: var(--color-button-primary-hover-background);
      border-color: var(--color-button-primary-hover-background);
      color: var(--color-button-primary-hover-text);
    }
  }

  &:active,
  &.active {
    &,
    &:focus {
      background-color: var(--color-button-primary-active-background);
      border-color: var(--color-button-primary-active-background);
      color: var(--color-button-primary-active-text);
    }
  }

  &:disabled,
  &.disabled {
    &,
    &:hover,
    &:focus {
      background-color: var(--color-button-primary-disabled-background);
      border-color: var(--color-button-primary-disabled-background);
      color: var(--color-button-primary-disabled-text);
      opacity: 1;
    }
  }
}

.btn-group.btn-multiple .btn-primary {
  &,
  &:focus {
    background-color: var(--color-button-multiple-background);
    border-color: var(--color-button-multiple-background);
    color: var(--color-button-multiple-text);
  }

  &:hover {
    &,
    &:focus {
      background-color: var(--color-button-primary-hover-background);
      border-color: var(--color-button-primary-hover-background);
      color: var(--color-button-primary-hover-text);
    }
  }

  &:active,
  &.active {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;

    &,
    &:focus {
      background-color: var(--color-button-primary-active-background);
      border-color: var(--color-button-primary-active-background);
      color: var(--color-button-primary-active-text);
    }
  }

  &:disabled,
  &.disabled {
    &,
    &:hover,
    &:focus {
      background-color: var(--color-button-primary-disabled-background);
      border-color: var(--color-button-primary-disabled-background);
      color: var(--color-button-primary-disabled-text);
      opacity: 1;
    }
  }
}

.btn-emphasis {
  background-color: var(--color-interactive-fill-secondary);
  color: var(--color-text-primary);

  &:hover {
    background-color: var(--color-interactive-fill-secondary-variant);
    color: var(--color-text-primary);
  }

  &:active,
  &.active {
    background-color: var(--color-interactive-fill-secondary-variant);
    color: var(--color-text-primary);
  }

  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover {
    background-color: var(--color-button-primary-disabled-background);
    border-color: var(--color-button-primary-disabled-background);
    color: var(--color-button-primary-disabled-text);
    opacity: 1;
  }
}
.btn-primary-inverted {
  background-color: transparent;
  border: 1px solid var(--color-button-primary-inverted-border);
  color: var(--color-button-primary-inverted-text);

  &:hover {
    background-color: var(--color-button-primary-inverted-hover-background);
    border-color: var(--color-button-primary-inverted-hover-background);
    color: var(--color-button-primary-inverted-hover-text);
  }

  &:active,
  &.active {
    background-color: var(--color-button-primary-inverted-active-background);
    border-color: var(--color-button-primary-inverted-active-background);
    color: var(--color-button-primary-inverted-active-text);
  }

  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover {
    background-color: transparent;
    border-color: var(--color-button-primary-inverted-disabled-border);
    color: var(--color-button-primary-inverted-disabled-text);
    opacity: 1;
  }
}

.btn.btn-secondary {
  border: 1px solid var(--color-bg-interactive-bg-neutral-1);
}

.btn.btn-tertiary {
  outline: none !important;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
  color: var(--color-text-variant);

  &:hover {
    color: var(--color-text-primary);
  }
  &:active,
  &.active {
    color: var(--color-text-emphasis);
  }
}

.btn.btn-dark {
  background-color: var(--color-black-80);
  color: white;

  &:hover {
    background-color: var(--color-black-95);
    color: white;
  }

  &:hover:disabled {
    background-color: var(--color-black-80);
    color: var(--color-muted);
    cursor: default;
    i {
      cursor: default;
    }
  }

  &:focus {
    background-color: var(--color-black-60);
    color: white;
    outline: none;
  }
}
