:root {
  --color-text-primary: var(--color-gray-950);
  --color-text-primary-inverse: var(--color-white);
  --color-text-variant: var(--color-gray-650);
  --color-text-variant-on-dark: var(--color-gray-150);
  --color-text-secondary: var(--color-gray-550);
  --color-text-emphasis: var(--color-cyan-500);
  --color-text-emphasis-on-dark: var(--color-cyan-400);
  --color-text-emphasis-variant: var(--color-cyan-600);
  --color-text-emphasis-variant-on-dark: var(--color-cyan-300);
  --color-text-emphasis-on-emphasis: var(--color-cyan-700);
  --color-text-emphasis-primary: var(--color-cyan-500);
  --color-text-disabled: var(--color-gray-350);
  --color-text-disabled-on-dark: var(--color-gray-450);
  --color-text-button-primary: var(--color-white);
  --color-text-button-inverted: var(--color-gray-950);
  --color-text-button-emphasis: var(--color-cyan-500);
  --color-text-button-emphasis-variant: var(--color-cyan-600);

  --color-border-primary: var(--color-gray-650);
  --color-border-primary-on-dark: var(--color-gray-150);
  --color-border-variant: var(--color-gray-450);
  --color-border-secondary: var(--color-gray-150);
  --color-border-emphasis: var(--color-cyan-500);
  --color-border-emphasis-on-dark: var(--color-cyan-300);
  --color-border-emphasis-variant: var(--color-cyan-400);
  --color-border-disabled: var(--color-gray-250);
  --color-border-disabled-on-dark: var(--color-gray-650);

  --color-interactive-fill-primary: var(--color-cyan-500);
  --color-interactive-fill-primary-on-dark: var(--color-cyan-500);
  --color-interactive-fill-primary-variant: var(--color-cyan-600);
  --color-interactive-fill-primary-variant-on-dark: var(--color-cyan-600);
  --color-interactive-fill-secondary: var(--color-zoined-yellow-250);
  --color-interactive-fill-secondary-variant: var(--color-zoined-yellow-300);
  --color-interactive-fill-neutral-low-contrast: var(--color-gray-050);
  --color-interactive-fill-neutral-mid-contrast: var(--color-gray-250);
  --color-interactive-fill-neutral-high-contrast: var(--color-gray-650);
  --color-interactive-fill-neutral-max-contrast: var(--color-gray-850);
  --color-interactive-fill-disabled: var(--color-gray-150);
  --color-interactive-fill-disabled-on-dark: var(--color-gray-650);

  --color-surface-neutral-no-contrast: var(--color-white);
  --color-surface-neutral-1: var(--color-gray-025);
  --color-surface-neutral-2: var(--color-gray-050);
  --color-surface-neutral-3: var(--color-gray-150);
  --color-surface-neutral-4: var(--color-gray-250);
  --color-surface-neutral-5: var(--color-gray-750);
  --color-surface-neutral-6: var(--color-gray-850);
  --color-surface-neutral-max-contrast: var(--color-gray-950);
  --color-surface-emphasis-1: var(--color-cyan-025);
  --color-surface-emphasis-2: var(--color-cyan-050);
  --color-surface-emphasis-3: var(--color-cyan-100);
  --color-surface-emphasis-4: var(--color-cyan-200);
  --color-surface-emphasis-inverse-1: var(--color-cyan-800);
  --color-surface-emphasis-inverse-2: var(--color-cyan-700);
  --color-surface-emphasis-inverse-3: var(--color-cyan-600);
  --color-surface-emphasis-inverse-4: var(--color-cyan-500);

  --color-semantic-positive-container: var(--color-green-025);
  --color-semantic-positive-text: var(--color-green-500);
  --color-semantic-positive-surface: var(--color-green-400);
  --color-semantic-negative-container: var(--color-red-025);
  --color-semantic-negative-text: var(--color-red-500);
  --color-semantic-negative-surface: var(--color-red-500);
  --color-semantic-negative-surface-on-dark: var(--color-red-400);
  --color-semantic-warning-container: var(--color-semantic-yellow-025);
  --color-semantic-warning-text: var(--color-semantic-yellow-600);
  --color-semantic-warning-icon: var(--color-semantic-yellow-300);
  --color-semantic-info-container: var(--color-semantic-blue-025);
  --color-semantic-info-text: var(--color-semantic-blue-600);
}
