html.public {
  --sidenav-iconbar-width: 0px;

  .hide-public {
    display: none !important;
  }

  .show-public {
    display: block !important;
  }
}
