///////////////////////////////////////
//////// HEADER & NAVIGATION //////////
///////////////////////////////////////

#simple-nav {
  border-bottom: 2px solid #3d3d3d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: var(--z-index-topbar);
  padding: 10px 0;

  img.logo {
    height: 40px;
  }
}

#zoined-nav {
  border: none;
  margin-bottom: 0;
  padding: 0 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: var(--z-index-topbar);
  border-radius: 0;

  .navbar-space {
    background-color: white;
    border-bottom: 1px solid var(--color-border-variant-2);
    @media screen and (min-width: $grid-float-breakpoint) {
      width: calc(100% - var(--sidenav-iconbar-width));
      border-bottom: none;
      box-shadow: 10px 10px 20px rgba(0, 7, 72, 0.14);
    }
  }

  .navbar-nav > li > .dropdown-menu {
    border-top-left-radius: $border-radius-base;
  }

  .container.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    min-height: var(--header-height);

    @media (min-width: $grid-float-breakpoint) {
      padding-top: 20px;
      padding-bottom: 0;
      align-items: flex-start;
    }

    .navbar-header.brand.left {
      @media (min-width: $grid-float-breakpoint) {
        display: none;
      }
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .navbar-nav,
  .navbar-header {
    float: none;
    margin: 0;
  }
  .navbar-header {
    .navbar-brand {
      background: transparent url("~@assets/images/logo.svg") no-repeat top left;
      text-indent: -9999px;
      padding: 0;
      width: 30px;
      height: 30px;
      margin: 0;
      // only show the graphic on mobile
      @media (min-width: $grid-float-breakpoint) {
        width: 152px;
      }
    }
  }

  .navbar-middle {
    text-align: center;
    //background-color: rgba(0,0,0,0.1);
    > li {
      display: inline-block;
      float: none !important; // none
      &.dropdown {
        margin-left: -4px;
        > a {
          padding: 5px 10px 0 0;
        }
      }
      > a {
        font-family: $display-font;
        font-size: 1em;
        //background-color: rgba(0,0,0,0.1);
        // we have to be careful with the line-height+padding+border
        // to get the nav links vertically centered
        line-height: 50px;
        padding: 5px 15px 0 15px;
        @media (max-width: $screen-sm-min) {
          padding: 5px 5px 0 5px;
          font-size: 0.9em !important;
        }
        @media (min-width: $grid-float-breakpoint) {
          font-size: 1.4em;
        }
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
      &.active > a {
        border-bottom: 5px solid $underline-accent;
      }
    }
  }

  .navbar-toggle {
    height: 30px;
    max-height: 30px;
    margin: 0;
    background: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon-bar {
      background-color: $navbar-inverse-toggle-hover-bg;
    }

    @media (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  @media (max-width: 991px) {
    #user-menu,
    #currency-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
    #user-menu {
      display: block;
    }
  }

  @media (max-width: 991px) {
    .navbar-collapse,
    .navbar-nav .open .dropdown-menu {
      position: fixed;
      left: 0;
      right: 0;
      top: var(--header-height);
      background-color: white;
      z-index: 9999;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
