// Tabs
//      Vue
.card {
    margin:20px 0;
    .show {opacity:100;}

    .card-header {
        .card-header-tabs {padding-left: 15px;}
        .nav-tabs {
            .nav-link {
                margin-bottom:-1px;
                &.active {background: #fff;border-right:1px solid #dedede;}
                &.disabled {color:#ccc;}
            }
        }
    }
    .tab-content {
        background: #fff;padding:20px 0;display: flex;
        .card-body {padding:0 0 20px 0;}
    }
} 