* {
  scrollbar-color: var(--color-primary);
}

/* chrome, edge, safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
*::-webkit-scrollbar-track {
  // background: var(--color-background);
}

*::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}
