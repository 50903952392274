zoined-xfilter {
  .controls {
    width: 100%;
    margin-bottom: 5px;

    .selectors {
      display: inline;
      ul.selector {
        display: inline;
      }
    }

    span[uib-dropdown] {
      cursor: pointer;
      a:hover {
        span.name {
          color: #4d4d4d;
        }
      }
      .trigger {
        font-size: 0.8em;
      }
    }

    .actions {
      display: inline-block;
      button {
        margin-top: -6px;
      }
    }

    .btn + .btn {
      margin-left: 5px;
    }
    .readonly-title {
      padding-left: 5px;
    }
  }
}
