.selectTemplate {
  background: no-repeat #fff;
  &.blank {
    background-image: url("~@assets/images/custom_report_editor/cr_blank.png");
  }
  &.actual_hours_4d {
    background-image: url("~@assets/images/custom_report_editor/cr_actual_hours_4d.png");
  }
  &.sales_report {
  }
}
.component {
  &.type-item {
    .chartImg {
      width: 137px;
      height: 60px;

      background: no-repeat;
      background-color: #efefef;
      background-size: 135px 83px;
      background-image: url("~@assets/images/custom_report_editor/cr_components_gen_2.png");

      border-style: dashed !important;
      border-color: #ddd;
      border-width: 1px;

      &.analysisd {
        background-image: url("~@assets/images/custom_report_editor/cr_components_analysisd_2.png");
      }
      &.basketscatter {
        background-image: url("~@assets/images/custom_report_editor/cr_components_basketscatter_2.png");
      }
      &.dualaxis {
        background-image: url("~@assets/images/custom_report_editor/cr_components_dualaxis_2.png");
      }
      &.dualaxiswithweather {
        background-image: url("~@assets/images/custom_report_editor/cr_components_dualaxis_with_weather.png");
      }
      &.metricchart {
        background-image: url("~@assets/images/custom_report_editor/cr_components_metric_chart_2.png");
      }
      &.heatmap {
        background-image: url("~@assets/images/custom_report_editor/cr_components_heatmap_2.png");
      }
      &.pyramid {
        background-image: url("~@assets/images/custom_report_editor/cr_components_pyramid_2.png");
      }
      &.crosstable {
        background-image: url("~@assets/images/custom_report_editor/cr_components_crosstable_2.png");
      }
      &.crosstabcustom {
        background-image: url("~@assets/images/custom_report_editor/cr_components_crosstabcustom.png");
      }
      &.pie {
        background-image: url("~@assets/images/custom_report_editor/cr_components_pie_2.png");
      }
      &.stacked {
        background-image: url("~@assets/images/custom_report_editor/cr_components_stacked_2.png");
      }
      &.trendinsights {
        background-image: url("~@assets/images/custom_report_editor/cr_components_trend_insights.png");
      }
      &.line {
        background-image: url("~@assets/images/custom_report_editor/cr_components_line.png");
      }
      &.salestrend {
        background-image: url("~@assets/images/custom_report_editor/cr_components_salestrend.png");
      }
      &.Sales {
        background-image: url("~@assets/images/custom_report_editor/cr_components_sales_2.png");
      }
    }
  }
}
