zoined-pill-selector {
  display: inline-block;
  
  > ul.selector {
    display: inline-block;
  }

  > ul.selector:not(:last-child) {
    margin-right: 0px;
  }

  .dropdown-menu {
    color: #333;
    padding: 4px;
    max-width: 360px;

    ul.menu {
      margin:0;
      padding: 0;
      
      li {
        list-style: none;
        padding: 0 0;
        margin: 0;
        line-height: 18px;
      }
      
      li > a {
        display: block;
        color: #555555;
        text-decoration: none;
        line-height: 18px;
        padding: 7px 10px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        font-size: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      
      li > a:hover {
        background-color: #4bcdf0;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
}